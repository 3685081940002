<template>
  <!-- Start hero #1-->
  <section class="hero hero-mailchimp bg-primary-alt new-hero" id="hero">
    <div class="container">
      <div class="hero-cotainer">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="hero-content">
              <h1 class="hero-headline">Telah Hadir Aplikasi QRCBN Market.</h1>
              <div class="hero-bio">Aplikasi ini adalah wadah bagi Penerbit dalam menjual Digital book terbitannya & menjangkau pembaca lebih luas.</div>
              <div class="hero-action text-center">
                <search/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6"><img class="img-fluid float-right" src="assets/images/cover/newBanner.png"
              alt="Book Cover" /></div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .hero-cotainer-->
    </div>
  </section>
  <!-- End #hero-->
</template>

<script>
import Search from './Component/Search.vue';
export default {
  components: {Search},
  data() {
    return {};
  },
};
</script>

<style>
.new-hero{
  padding-bottom: 0 !important;
}
</style>